import * as React from "react"
import { graphql, Link } from "gatsby"

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import LeadForm from "../../components/Contact";

const BlogTemplate = ({ pageContext, data, location }) => {

  const currentPage = () => {
    return location.pathname.replace(pageContext.localizedUrls[pageContext.locale], '').replace(/\//g, '')
  }

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: 'https://www.spinecentre.com.hk/_img/banners/blog.jpg'
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl="/_img/banners/blog.jpg"
      />
      <main className="my-12 flex justify-between w-full flex-col">
        <div className="mt-12 lg:px-44 max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">
          {data.firestore.articles.map((post) => (
            <Link key={post.id} to={`/${pageContext.locale}/back-health-blog${post.pathname}`} className="flex flex-col mx-8 lg:mx-0 rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="h-48 lg:h-64 w-full object-cover" src={post.thumbnail} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xs lg:text-sm font-medium text-mango">
                    {
                      post.tags.slice(0, 3).map(tag => (
                        <a href={'#'} className="hover:underline mr-2">
                          {tag}
                        </a>
                      ))
                    }
                  </p>
                  <div className="block mt-2">
                    <p className="text-lg lg:text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-sm lg:text-base text-gray-500">{post.excerpt}</p>
                  </div>
                </div>
                {/* <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={'#'}>
                      <span className="sr-only">{'Testing Author'}</span>
                      <img className="h-10 w-10 rounded-full" src={'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'} alt="" />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={'#'} className="hover:underline">
                        {'Testing Author'}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.publishedAt}>{post.publishedAt}</time>
                    </div>
                  </div>
                </div> */}
              </div>
            </Link>
          ))}
        </div>
        <div className="mt-12 flex justify-center">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <a
              className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 ${pageContext.hasPreviousPage?'cursor-pointer':'cursor-not-allowed'} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {
              Array.from({ length: pageContext.numberOfPages }).map((_, i) => (
                <a
                  key={i===0?pageContext.localizedUrls[pageContext.locale]:`${pageContext.localizedUrls[pageContext.locale]}/${i+1}`}
                  href={i===0?pageContext.localizedUrls[pageContext.locale]:`${pageContext.localizedUrls[pageContext.locale]}/${i+1}`}
                  className={`${(currentPage()===''&&i===0)||parseInt(currentPage())===i+1?'text-mango':'text-gray-700'} -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
                >
                  {i+1}
                </a>
              ))
            }
            <a
              className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 ${pageContext.hasNextPage?'cursor-pointer':'cursor-not-allowed'} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-mango focus:border-mango`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </span>
        </div>
      </main>
      <LeadForm locale={pageContext.locale} />
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplateQuery($pageId: ID, $locale: String!, $limit: Int!, $cursor: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
      articles(locale: $locale, paginated: true, pageSize: $limit, cursor: $cursor) {
        id
        title
        tags
        excerpt
        pathname
        thumbnail
        publishedAt
      }
    }
  }
`